export const GoldenFalconSvg = () => {
  return (
    <svg
      width="70"
      height="60"
      viewBox="-60 -30 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M94.5 0C85.5835 76.0761 102.813 122.786 172.5 213C195.873 242.114 196.012 258.979 174.5 290C193.835 273.606 204.865 263.497 220.5 265C229.839 264.406 233.683 267.759 239.5 276.5C239.8 267.79 237.914 264.208 229.5 261C233.191 260.217 235.079 260.639 238 263.5C236.124 251.783 232.134 247.043 218 242C293.421 244.418 331.689 237.328 383.5 193C377.152 220.933 367.691 230.381 343 239L368.5 234.5C350.29 258.287 331.064 263.491 286.5 263.5C301.136 267.855 310.075 268.362 326.5 268C309.232 277.196 296.051 279.823 258 274C264.55 278.725 272.261 280.307 286.5 283C273.899 287.948 265.11 289.013 247 288.5C207.794 286.059 194.189 288.073 188.5 307C188.5 307 186.208 324.307 181 338.5C175.834 350.373 169.101 352.675 152 351C161.014 344.642 164.516 340.8 165.5 333C150.901 347.375 139.282 348.074 121 336.5C137.082 333.449 143.324 330.547 149 323C125.682 331.295 111.4 331.395 102 316C120.948 315.272 129.068 314.066 136 309.5C127.535 310.189 122.979 310.048 115.5 308C129.715 299.287 134.498 292.387 137.5 275.5C132.684 265.022 127.613 259.898 111.5 253C73.6981 239.417 54.535 230.779 37 207C55.4988 211.769 64.5437 213.396 76.5 213C25.9426 194.623 1.39731 179.675 0 138C18.8138 159.257 29.4566 164.771 48.5 170.5C13.7624 143.769 -3.60632 127.669 7 77C12.3221 98.5706 22.3239 106.899 42.5 120.5C21.7246 87.7731 16.2033 69.0846 38 34C42.2951 55.4907 46.6374 65.8905 59 80.5C57.865 29.5714 65.5785 12.1018 94.5 0Z"
        fill="#D8B600"
      />
    </svg>
  );
};
